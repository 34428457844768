<template>
  <div class="position-relative">
    <div class="card alert-primary border-primary mb-3">
      <div class="card-body p-3">
        <h5 class="d-flex justify-content-between">
          <span>
            <span class="text-blue">[{{roundGroupName}}]</span>
            <span class="text-blue"> {{roundMarketName}}</span>
          </span>
          <span>{{roundDate}}</span>
        </h5>
        <div class="form-inline mt-2 d-flex justify-content-between">
          <div>
            <span class="my-1 mr-2">อัตราจ่าย:</span>
            <span>{{rateTitle}}</span>
          </div>
          <div v-if="roundIcon" class="float-right">
            <img :src="roundIcon" style="width: 60px;">
          </div>
        </div>
      </div>
    </div>

    <h5>กรุณายืนยันรายการ</h5>
    <div class="mb-3">
      <table class="table table-bordered table-sm table-info">
        <thead class="bg-info">
          <tr class="text-center">
            <th>ประเภท</th>
            <th>หมายเลข</th>
            <th>ยอดเดิมพัน</th>
            <th>เรทจ่าย</th>
            <th>ส่วนลด</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in ticket.rows" :key="index" :class="[{'alert-danger':!row.isValid}, {'alert-warning':row.isWarning}]">
            <td class="text-center"><span>{{ row.text }}</span></td>
            <td class="text-center"><span class="text-primary">{{ row.number }}</span></td>
            <td class="text-right">{{ row.amount | amountFormat(2) }} <small v-if="!row.isValid || row.isWarning" class="text-danger">({{ row.note }})</small></td>
            <td class="text-right"><span>{{ row.pay | amountFormat(2) }}</span></td>
            <td class="text-right"><span>{{ row.discount | amountFormat(2) }}</span></td>
            <td class="text-center"><span class="btn-delete cursor-pointer" style="cursor: pointer;" @click="removeRow(index)"><i class="far fa-trash-alt text-danger bigger-175"></i></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="invalidItems">
      <h6 class="text-danger">***แจ้งเตือน***</h6>
      <div class="mb-3">
        <table class="table table-bordered table-sm table-danger">
          <thead class="bg-danger">
            <tr class="text-center">
              <th width="25%">ประเภท</th>
              <th width="20%">หมายเลข</th>
              <th>หมายเหตุ</th>
            </tr>
          </thead>
          <tbody>
            <tr class="alert-danger" v-for="(row, index) in invalidItems" :key="'invalid-'+index">
              <td class="text-center">{{ row.text }}</td>
              <td class="text-center">{{ row.number }}</td>
              <td class="text-center">{{ row.note }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h5 class="text-center">
      ยอดเดิมพัน <span> {{ total.amount | amountFormat(2) }} </span> บาท
    </h5>
    <h5 class="text-center">
      ส่วนลด <span class="text-danger"> {{ total.discount | amountFormat(2) }} </span> บาท
    </h5>
    <h5 class="text-center">
      รวม <span class="text-primary"> {{ total.credit | amountFormat(2) }} </span> บาท
    </h5>
    <p class="text-center">หมายเหตุ: {{ ticket.remarks }}</p>

    <div class="row justify-content-center">
      <div class="col-3 pr-1">
        <button class="btn btn-block btn-danger" type="button" @click="cancel">ยกเลิก</button>
      </div>
      <div class="col-3 px-1">
        <button class="btn btn-block btn-dark" type="button" @click="back">ย้อนกลับ</button>
      </div>
      <div class="col-3 pl-1">
        <button class="btn btn-primary btn-block" type="button" @click="confirm">ยืนยัน</button>
      </div>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import Swal from 'sweetalert2'
import LotteryService from '@/services/LotteryService'
import cAlert from '@/helpers/alert'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'RoundTicket',
  components: {
    Loading
  },
  props: ['round', 'ticket'],
  data() {
    return {
      isLoading: false,
      invalidItems: null,
      queueId: null,
      queueRunning: 1,
      statusTimeout: null,
      queueId: null
    }
  },
  computed: {
    balance() {
      return this.$store.state.account.balance || 0
    },
    roundGroupName() {
      if(!this.round)
        return ''

      return this.round?.note.groupTitle
    },
    roundMarketName() {
      if(!this.round)
        return ''

      return this.round?.note.marketTitle
    },
    roundDate() {
      if(!this.round)
        return ''

      return moment(this.round.roundDate.date).format("DD-MM-YYYY")
    },
    roundIcon() {
      if(!this.round)
        return false

      return this.round?.market?.imageIcon || false
    },
    rate() {
      return this.$store.state.round.rate
    },
    rateTitle() {
      return this.rate?.rateTitle || ''
    },
    total() {
      const total = this.ticket.rows.reduce((total, row)=>{
        total.amount += row.amount
        total.discount += row.discount
        total.credit += row.credit
        return total
      }, {
        amount: 0,
        discount: 0,
        credit: 0
      })
      return total
    }
  },
  methods: {
    removeRow(index) {
      this.ticket.rows.splice(index, 1)
    },
    cancel() {
      this.$emit('cancel')
    },
    back() {
      this.$emit('back')
    },
    confirm() {

      /**
       * ไม่มีรายการแทง
       */
      if(this.ticket.rows.length === 0) {
        return Swal.fire({
          text: 'ไม่มีรายการแทงเลข',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      /**
       * ตรวจสอบยอดเดิมพัน
       */
      const invalidRows = this.ticket.rows.filter(row=>!row.isValid)
      if(invalidRows.length > 0) {
        return Swal.fire({
          text: 'กรุณาตรวจสอบรายการเดิมพันให้ถูกต้อง',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      /**
       * ตรวจสอบยอดเงินในบัญชี
       */
      if(this.total.credit > this.balance) {
        const balance = this.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        this.$store.dispatch('reloadBalance')
        return Swal.fire({
          title: 'ยอดเงินในบัญชีไม่พอ',
          text: `ยอดเงินคงเหลือ ${balance}`,
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      this.isLoading = true
      this.$store.commit('updateProcessTicketStatus', true)
      this.invalidItems = null
      this.ticket.queueId = this.queueId
      LotteryService.createTicket(this.ticket)
      .then((response)=>{
        if(response.success) {
          this.queueId = response.data.queueId

          if(response.data.status === 'Complete') {
            this.$emit('success')
            this.closeLoading()
          }else
          if(response.data?.status === 'Success') {
            this.checkStatus()
          }else{
            Swal.fire({
              title: 'ส่งโพย!',
              icon: 'warning',
              html: `กำลังต่อคิว กรุณารอซักครู่...<span class="text-primary">${this.queueRunning}</span>`,
              didOpen: () => {
                Swal.showLoading()
              },
              willClose: () => {
                console.log('close!!!')
              },
              allowOutsideClick: false,
              showConfirmButton: true,
              confirmButtonText: 'ตรวจสอบสถานะ'
            })
            this.statusTimeout = setTimeout(this.checkStatus, 5000)
          }
        }else{
          throw new Error(response)
        }
      })
      .catch((e)=>{

        console.log(e?.name)

        this.closeLoading()

        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'ส่งโพยไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        }, ()=>{
          /**
           * error code 501 = ให้ redirect ไปหน้ารวมหวย
           */
          if(e?.status === 501) {
            this.$router.push({name: 'PlayLotto'})
          }
        })

        /**
         * รายการเลขไม่ถูกต้อง
         */
        if(e?.errors[0]?.title === 'TicketItemException') {
          this.invalidItems = e?.errors[0].data
        }
      })
    },
    checkStatus(force=false) {
      clearTimeout(this.statusTimeout)

      LotteryService.queueStatus(this.queueId)
      .then((response)=>{
        if(response.data.status === 'Success') {
          this.$emit('success')
          this.closeLoading()
        }else
        if(['Waiting', 'Processing'].includes(response.data.status)) {
          this.updateRunning()
          this.statusTimeout = setTimeout(this.checkStatus, 10000)
        }
      })
      .catch((e)=>{
        if(e?.name === 'TicketError') {
          cAlert({
            ...e,
            title: 'ผิดพลาด!',
            text: e?.message || 'ส่งโพยไม่สำเร็จ',
            icon: 'error',
            confirmButtonText: 'OK'
          }, ()=>{
            this.closeLoading()
          })

          if(e?.errors[0]?.title === 'TicketItemException') {
            this.invalidItems = e?.errors[0].data
          }
        }else{
          cAlert({
            title: 'ระบบเชื่อมต่อมีปัญหา!',
            text: 'กรุณาตรวจสอบโพยที่หน้า "รายการแทง"',
            icon: 'warning',
            confirmButtonText: 'OK'
          }, ()=>{
            this.closeLoading()
            this.$router.push({name: 'Tickets'})
          })
        }
      })
    },
    updateRunning() {
      this.queueRunning++
      const running = Swal.getHtmlContainer().querySelector('span')
      running.textContent = this.queueRunning
    },
    closeLoading() {
      this.isLoading = false
      this.$store.commit('updateProcessTicketStatus', false)
      Swal.close()
    }
  },
  beforeDestroy() {
    clearTimeout(this.statusTimeout)
  }
}
</script>
<style lang="scss" scoped>
.table {
  font-size: 90%;
  background-color: #FFF;

  thead {
    th {
      font-weight: normal !important;
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
