<template>
  <b-card-body class="p-0">
    <div class="flag-bg p-3" :style="cssBackground">
      <h5>วางโพย</h5>

      <div class="mb-3">
        <textarea
          rows="5"
          class="form-control"
          v-model="inputBulk"
          ref="inputBulk"
          v-on:keydown="keypressBulk"
        ></textarea>
      </div>

      <div class="mt-3">
        <button
          v-for="(number, index) in inputNumbers"
          class="btn btn-sm btn-info mb-2 mx-1"
          @click="toggleRemove(index)"
        >{{number}}</button>
      </div>

      <div ref="bulkNumber" class="row mb-3">
        <label class="font-weight-bold col-12 mb-0">ใส่ราคา</label>
        <div class="col pr-1 text-center">
          <label class="small mb-1">บน</label>
          <b-form-input
            type="text"
            class="text-center form-control express"
            name="amountTop"
            v-on:keydown="keypress"
            v-model="amountTop"
            :number="true"
          />
        </div>
        <div class="col px-1 text-center">
          <label class="small mb-1">ล่าง</label>
          <b-form-input
            type="text"
            class="text-center form-control express"
            name="amountTop"
            v-on:keydown="keypress"
            v-model="amountBot"
            :number="true"
          />
        </div>
        <div class="col px-1 text-center">
          <label class="small mb-1">โต๊ด</label>
          <b-form-input
            type="text"
            class="text-center form-control express"
            name="amountTop"
            v-on:keydown="keypress"
            v-model="amountTod"
            :number="true"
          />
        </div>

        <div class="col-auto pl-1 mt-2 text-center align-self-end">
          <button type="button" class="btn btn-info express" v-on:keydown="keypress" @click="addBill"><i class="fas fa-plus mt-1"></i> เพิ่มบิล</button>
        </div>
      </div>

      <table class="table">
        <tbody v-for="(bill, index) in bills">
          <tr>
            <td class="text-center" style="width: 150px;">
              <div class="font-weight-light">{{ bill.text.title }}</div>
              <div class="font-weight-light">
                <span>{{ bill.text.description }}</span>
              </div>
              <div class="font-weight-bold">
                <span>{{ bill.text.amount }}</span>
              </div>
            </td>
            <td>
              <div class="d-flex flex-wrap w-100">
                <span class="pr-2" v-for="num in bill.numbers">{{num}}</span>
              </div>
            </td>
            <td class="text-center" style="width: 40px;">
              <span class="btn-delete" style="cursor: pointer;" @click="toggleRemoveBill(index)">
                <i class="far fa-trash-alt text-danger bigger-175"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-card-body>
</template>
<script>
import Swal from 'sweetalert2'

export default {
  name: 'BulkForm',
  props: ['round', 'bills'],
  data() {
    return {
      elements: null,
      amountTop: null,
      amountBot: null,
      amountTod: null,
      inputBulk: '',
      inputNumbers: []
    }
  },
  computed: {
    numbers() {
      return this.inputBulk
        .replace(/[^0-9]/g, ' ')
        .split(' ')
        .filter(n => n && n.length >= 1 && n.length <= 3)
        .reduce((ns, n) => {
          if (!ns.includes(n)) {
            ns.push(n)
          }
          return ns
        }, [])
    },
    cssBackground() {
      if(!this.round || !this.round?.market?.imageBg)
        return ''

      return {
        '--background-url': `url('${this.round.market.imageBg}')`
      }
    },
    rate() {
      return this.$store.state.round.rate
    },
    isOpenThreeTop() {
      return this.rate?.openBets.threeNumberTop.isAvailable || false
    },
    isOpenThreeTod() {
      return this.rate?.openBets.threeNumberTode.isAvailable || false
    },
    isOpenTwoTop() {
      return this.rate?.openBets.twoNumberTop.isAvailable || false
    },
    isOpenTwoBot() {
      return this.rate?.openBets.twoNumberBottom.isAvailable || false
    },
    isOpenRunTop() {
      return this.rate?.openBets.runTop.isAvailable || false
    },
    isOpenRunBot() {
      return this.rate?.openBets.runBottom.isAvailable || false
    }
  },
  watch: {
    numbers() {
      this.inputNumbers = this.numbers
    }
  },
  methods: {
    addBill() {

      /**
       * ยังไม่กรอกเลข
       */
      if(this.inputNumbers.length === 0)
        return Swal.fire({
          text: 'กรุณาใส่เลขที่จะเล่น',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.focusInputNumber()
        })

      /**
       * ไม่ใส่ราคา
       */
      if(!this.amountTop && !this.amountBot && !this.amountTod)
        return Swal.fire({
          text: 'กรุณาใส่ยอดเงินที่จะเล่น',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.focusInputAmount()
        })

      const amountTop = this.amountTop || 0
      const amountBot = this.amountBot || 0
      const amountTod = this.amountTod || 0

      /**
       * หาเลขวิ่ง
       */
      const runNumbers = this.inputNumbers.filter(n=>n.length===1)
      if(runNumbers.length) {

        const maxTop = this.rate.betLimit.runTop.max
        const maxBot = this.rate.betLimit.runBottom.max

        /**
         * ตรวจสอบว่าเปิดรับแทงหรือไม่
         */
        const amountRunTop = this.isOpenRunTop ? amountTop : 0
        const amountRunBot = this.isOpenRunBot ? amountBot : 0

        const billAmount = runNumbers.reduce((total, number)=>{
          total += (amountRunTop + amountRunBot)
          return total
        }, 0)
        console.log('bill-1', billAmount)

        const bill = {
          type: 'runNumber',
          text: {
            title: 'เลขวิ่ง',
            description: 'วิ่งบน x วิ่งล่าง',
            amount: `${amountRunTop} x ${amountRunBot}`
          },
          numbers: runNumbers,
          amountTop: amountRunTop,
          amountBot: amountRunBot,
          billAmount: billAmount
        }

        /**
         * มีตัวใดตัวหนึ่งเปิดให้เพิ่มบิล
         */
        if(this.isOpenRunTop || this.isOpenRunBot) {
          this.bills.push(bill)
        }
      }

      /**
       * หาเลข 2 ตัว
       */
      const twoNumbers = this.inputNumbers.filter(n=>n.length===2)
      if(twoNumbers.length) {

        const maxTop = this.rate.betLimit.twoNumberTop.max
        const maxBot = this.rate.betLimit.twoNumberBottom.max

        /**
         * ตรวจสอบว่าเปิดรับแทงหรือไม่
         */
        const amountTwoTop = this.isOpenTwoTop ? amountTop : 0
        const amountTwoBot = this.isOpenTwoBot ? amountBot : 0

        const billAmount = twoNumbers.reduce((total, number)=>{
          total += (amountTwoTop + amountTwoBot)
          return total
        }, 0)
        console.log('bill-2', billAmount)

        const bill = {
          type: 'twoNumber',
          text: {
            title: '2 ตัว',
            description: 'บน x ล่าง',
            amount: `${amountTwoTop} x ${amountTwoBot}`
          },
          numbers: twoNumbers,
          amountTop: amountTwoTop,
          amountBot: amountTwoBot,
          billAmount: billAmount
        }

        /**
         * มีตัวใดตัวหนึ่งเปิดให้เพิ่มบิล
         */
        if(this.isOpenTwoTop || this.isOpenTwoBot) {
          this.bills.push(bill)
        }
      }

      /**
       * หาเลข 3 ตัว
       */
      const threeNumbers = this.inputNumbers.filter(n=>n.length===3)
      if(threeNumbers.length) {

        const maxTop = this.rate.betLimit.threeNumberTop.max
        const maxTod = this.rate.betLimit.threeNumberTode.max

        /**
         * ตรวจสอบว่าเปิดรับแทงหรือไม่
         */
        const amountThreeTop = this.isOpenThreeTop ? amountTop : 0
        const amountThreeTod = this.isOpenThreeTod ? amountTod : 0

        const billAmount = threeNumbers.reduce((total, number)=>{
          total += (amountThreeTop + amountThreeTod)
          return total
        }, 0)
        console.log('bill-3', billAmount)

        const bill = {
          type: 'threeNumber',
          text: {
            title: '3 ตัว',
            description: 'บน x โต๊ด',
            amount: `${amountThreeTop} x ${amountThreeTod}`
          },
          numbers: threeNumbers,
          amountTop: amountThreeTop,
          amountTod: amountThreeTod,
          billAmount: billAmount
        }

        /**
         * มีตัวใดตัวหนึ่งเปิดให้เพิ่มบิล
         */
        if(this.isOpenThreeTop || this.isOpenThreeTod) {
          this.bills.push(bill)
        }
      }

      this.amountTop = null
      this.amountBot = null
      this.amountTod = null
      this.inputBulk = ''
    },
    toggleRemove(index) {
      this.inputNumbers.splice(index, 1)
    },
    keypress(event) {

      const focusable = this.elements
      const els = []
      focusable.forEach((el)=>{
        els.push(el)
      })

      const cIndex = els.findIndex(el=>el===event.target)
      // ไปข้างหน้า
      if(['Enter', 'ArrowRight', 'ArrowDown', 'Tab'].includes(event.key)) {
        const nIndex = cIndex + 1
        if(nIndex < focusable.length) {
          focusable[nIndex].focus()
          event.preventDefault()
        }
      }else // ย้อนกลับ
      if(['ArrowLeft', 'ArrowUp'].includes(event.key)) {
        const nIndex = cIndex - 1
        if(nIndex >= 0) {
          focusable[nIndex].focus()
          event.preventDefault()
        }
      }else
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(event.key)) {
        event.preventDefault()
      }
    },
    keypressBulk(event) {
      if(event.key === 'Tab') {
        this.elements[0].focus()
        event.preventDefault()
      }
    },
    toggleRemoveBill(index) {
      this.bills.splice(index, 1)
    },
    focusInputNumber() {
      setTimeout(()=>{
        this.$refs.inputBulk.focus()
      }, 500)
    },
    focusInputAmount() {
      setTimeout(()=>{
        this.elements[0].focus()
      }, 500)
    }
  },
  mounted() {
    this.elements = this.$refs.bulkNumber.getElementsByClassName('express')
    this.focusInputNumber()
  }
}
</script>
<style lang="scss" scoped>
.flag-bg::before {
  content: "";
  background-repeat: repeat-y;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: 100% 100%;
  background-position: center;
  position: absolute;
  z-index: 0;
  background-image: var(--background-url);
  opacity: 0.2;
}

.flag-bg, .flag-bg div, .flag-bg h5, .flag-bg table {
  position: relative;
}

table {
  color: #000;
  border-spacing: 0px 10px;
  border-collapse: separate;

  tr {
    background-color: #FFF;
  }

  td {
    vertical-align: middle;
    border-top: 0px;
  }

  td:first-child {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-right: 1px solid #ccc;
  }

  td:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
}
</style>
